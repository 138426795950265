import { default as _91documentType_93mG6PQuWUPYMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue?macro=true";
import { default as indexqM2PSFpRp8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue?macro=true";
import { default as activity0Jlg6G7rZHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addressmAkxLL3vnxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developerKuU3KDMYWSMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_93LD3H3BlwtAMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as index9HMRlt6a7MMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksxBUwZtrzyrMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profilemJSYbFsvJZMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralR6OPzEjIjnMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseidSHz6DIYvdHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as securityxcJyIyh0S4Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as settingsB8xrK6CvZUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_93YYkJRDaYifMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93JCriLUAE82Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45walletRsqU5r6PYHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binance1P7fS1k9SHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbase0xMAGJ3QLnMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdmtidiVHFwbMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletj5zSto4TNLMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europepo9Rfw0uv2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexmmRRvOL4tfMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbp0fdiipkrT8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultjb9UetILosMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdPi8hUWe2eoMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenjDDxEhs1SJMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnzVVlny0QUkMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addDiWBp0cuqpMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexnNvAxaPN9qMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardyjSdHxM5H5Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as completenLdvFVmPXeMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingmeHiREPa1jMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_933IGGu3wb80Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexrHzgB7htaTMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexlebNFQEdjpMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as tasksEEbqDa53djMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as indexgtnhUr7DOKMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93XCblFp82ZWMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexQ23eNksF7RMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as crypto5wtjMMwKzlMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatHpAB1PFMdfMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directixR3T12txGMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdrawnn4I1SvWyrMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as arbitrum_45walletNoSh5gz05lMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binance013JE9dzqJMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbaseUoFAfA54HQMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdH9wGACEYltMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletajYtOBpgpZMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europeAveFntfcjmMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexDnv20IWqJYMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbpOuKc0zZk0pMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultZGk52JRZ5HMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdyeIGyhwsN0Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as kraken8N57Cwfq9hMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnxGCOUTQMpIMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addvfuLGXRNDrMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue?macro=true";
import { default as index6JZCXiKqFcMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue?macro=true";
import { default as benefitsI9tyT4UYCaMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardFvf1W6h5vmMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as employee_settings0czP3Xz48nMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue?macro=true";
import { default as completeqFOFvEKBz4Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue?macro=true";
import { default as pending6CoS4LmNkuMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue?macro=true";
import { default as pay_45slipsDbcjHfyjD8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as _91id_93bDJ0cYQNyRMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexGVzFuF4aluMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexMLZtBc4WzkMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue?macro=true";
import { default as reimbursementsMokciIAi4UMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksRhHNk8FtAQMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as cryptoA85psrskjyMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatZK6EeHCGhwMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as withdrawm0wbh9baLPMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexSEVeXj8qpPMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93Osx4NmuPaUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtfSyd5b5JTxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as callback8yOZRkeBtnMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue?macro=true";
import { default as administratorsIJrHx9k8HhMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboardqKHZqWhr9VMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as completeTy1WtyR7joMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingaWN5tM6Q14Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directIuHEsEnwpIMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmNyArsh0JGbMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesIe2rSOfhQAMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressNxjGAuHr9pMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as configs0OEsimGiWEMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue?macro=true";
import { default as detailsPkL88kPiAqMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownershiptr62imcHDxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingsMwXeNik6O6Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscriptionFVl5MecGkOMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as tasks1ov4p8PRgBMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addDoquanAqGIMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as indexql0MCWtAw0Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transferJfIRVFKlduMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45addressQtWhAYeu5YMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wire79bTCzJTivMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositRN3seilCtwMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundHFVZIIk96eMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93ud7hhUkaprMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalBKHkr0CY1gMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2falmX6mqA7Y2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as codeqrxxZptSOnMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indextIjxuGAIAwMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outDKrtvP1b2dMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codeICnZFBsQ6yMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexhsfRuhnQ6WMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingXXUY2M3mJWMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as dashboard6RIsnZYx9FMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as dashboardOLDSMCpaeFhbAMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue?macro=true";
import { default as blockchain_45addressguCIvyrmY6Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wireemikEi8tNgMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as depositxzWyZwpGVCMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as editQfOXJiRLqwMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue?macro=true";
import { default as completeS4Ec0AiFXNMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingELAMP3KjnjMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchE2wDX4yuDeMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directE8LfGvFcMgMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmD0TOzw0OnHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invites4pZ2EtQvS8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsTVGU0gezmCMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summaryL8i37XRCr5Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93SCOnXerg2VMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexdxnZ24DC4FMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csvdi1ydI9W0MMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexxVaLgYEJ7yMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexISTRZvmSSbMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directXK2yqFooWyMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instant1ypbovSIhgMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestoneaQr5DrGt57Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as once9PeXEdcNrDMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurring1JsyYk7zH1Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93kTC9HtdGVUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payable3UM1tm5lspMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as employees5SmBgDHknYMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue?macro=true";
import { default as paused5kA8J1PP58Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue?macro=true";
import { default as pending_actions6VGVhu2v3CMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue?macro=true";
import { default as currentAroROkIWy3Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue?macro=true";
import { default as pay_slipsBNYlg1M0W8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue?macro=true";
import { default as previousu8F55UBujTMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue?macro=true";
import { default as reportsu0JOskiDcWMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue?macro=true";
import { default as settings0bRB7dfI36Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue?macro=true";
import { default as endeduwG9avfQ9aMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue?macro=true";
import { default as payrolls7pfHqhpv8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue?macro=true";
import { default as severanceQ8v6dxzRJ2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue?macro=true";
import { default as tax_documentsJKPz82Wc44Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue?macro=true";
import { default as payrollJEcS6pB3sUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue?macro=true";
import { default as settingsUbLPHf4APqMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasks5X73hW21vpMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-documents-documentId-versionId-documentType",
    path: "/:scope()/:workspaceId()/documents/:documentId()/:versionId()/:documentType()",
    meta: _91documentType_93mG6PQuWUPYMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents",
    path: "/:scope()/:workspaceId()/documents",
    meta: indexqM2PSFpRp8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activity0Jlg6G7rZHMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue").then(m => m.default || m)
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addressmAkxLL3vnxMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerKuU3KDMYWSMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93LD3H3BlwtAMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: index9HMRlt6a7MMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksxBUwZtrzyrMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profilemJSYbFsvJZMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralR6OPzEjIjnMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseidSHz6DIYvdHMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityxcJyIyh0S4Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsB8xrK6CvZUMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addDiWBp0cuqpMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexnNvAxaPN9qMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardyjSdHxM5H5Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completenLdvFVmPXeMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingmeHiREPa1jMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_933IGGu3wb80Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexrHzgB7htaTMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexlebNFQEdjpMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksEEbqDa53djMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: indexgtnhUr7DOKMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93XCblFp82ZWMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexQ23eNksF7RMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdrawnn4I1SvWyrMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts-add",
    path: "/employees/:workspaceId()/accounts/add",
    meta: addvfuLGXRNDrMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts",
    path: "/employees/:workspaceId()/accounts",
    meta: index6JZCXiKqFcMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsI9tyT4UYCaMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardFvf1W6h5vmMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-employee_settings",
    path: "/employees/:workspaceId()/employee_settings",
    meta: employee_settings0czP3Xz48nMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-complete",
    path: "/employees/:workspaceId()/history/complete",
    meta: completeqFOFvEKBz4Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-pending",
    path: "/employees/:workspaceId()/history/pending",
    meta: pending6CoS4LmNkuMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsDbcjHfyjD8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat-id",
    path: "/employees/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93bDJ0cYQNyRMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat",
    path: "/employees/:workspaceId()/receivable/flat",
    meta: indexGVzFuF4aluMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable",
    path: "/employees/:workspaceId()/receivable",
    meta: indexMLZtBc4WzkMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsMokciIAi4UMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksRhHNk8FtAQMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawm0wbh9baLPMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexSEVeXj8qpPMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93Osx4NmuPaUMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callback8yOZRkeBtnMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorsIJrHx9k8HhMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboardqKHZqWhr9VMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completeTy1WtyR7joMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingaWN5tM6Q14Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invitesIe2rSOfhQAMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingsMwXeNik6O6Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-configs",
    path: "configs",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionFVl5MecGkOMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: tasks1ov4p8PRgBMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addDoquanAqGIMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: indexql0MCWtAw0Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transferJfIRVFKlduMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositRN3seilCtwMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressQtWhAYeu5YMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wire79bTCzJTivMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93ud7hhUkaprMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalBKHkr0CY1gMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    meta: _2falmX6mqA7Y2Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: codeqrxxZptSOnMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indextIjxuGAIAwMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codeICnZFBsQ6yMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexhsfRuhnQ6WMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingXXUY2M3mJWMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboard6RIsnZYx9FMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboardOLD",
    path: "/teams/:workspaceId()/dashboardOLD",
    meta: dashboardOLDSMCpaeFhbAMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositxzWyZwpGVCMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressguCIvyrmY6Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wireemikEi8tNgMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-edit",
    path: "/teams/:workspaceId()/edit",
    meta: editQfOXJiRLqwMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeS4Ec0AiFXNMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingELAMP3KjnjMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invites4pZ2EtQvS8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93SCOnXerg2VMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexdxnZ24DC4FMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csvdi1ydI9W0MMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexxVaLgYEJ7yMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexISTRZvmSSbMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93kTC9HtdGVUMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directXK2yqFooWyMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instant1ypbovSIhgMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestoneaQr5DrGt57Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: once9PeXEdcNrDMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurring1JsyYk7zH1Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payable3UM1tm5lspMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: payrollJEcS6pB3sUMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current",
    path: "current",
    meta: currentAroROkIWy3Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current-employees",
    path: "employees",
    meta: employees5SmBgDHknYMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-paused",
    path: "paused",
    meta: paused5kA8J1PP58Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-pending_actions",
    path: "pending_actions",
    meta: pending_actions6VGVhu2v3CMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-pay_slips",
    path: "pay_slips",
    meta: pay_slipsBNYlg1M0W8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-previous",
    path: "previous",
    meta: previousu8F55UBujTMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-reports",
    path: "reports",
    meta: reportsu0JOskiDcWMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-settings",
    path: "settings",
    meta: settings0bRB7dfI36Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance",
    path: "severance",
    meta: severanceQ8v6dxzRJ2Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-severance-ended",
    path: "ended",
    meta: endeduwG9avfQ9aMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance-payroll",
    path: "payroll",
    meta: payrolls7pfHqhpv8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-tax_documents",
    path: "tax_documents",
    meta: tax_documentsJKPz82Wc44Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsUbLPHf4APqMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasks5X73hW21vpMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]